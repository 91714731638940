<template>
  <div>
    <auth-forms-wrapper show-links>
      <template #form>
        <v-row>
          <v-col>
            <v-img
              width="100%"
              justify="center"
              rel="preload"
              align="center"
              class="mx-auto mt-n12"
              :src="$images.loggedOut"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center mt-n7">
            <h3 class="text--primary font--secondary">
              Logged out Successfully!
            </h3>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="text-center py-5">
            <v-btn
              block
              x-large
              color="loginBtn"
              :loading="isLoading"
              class="font-weight-bold white--text"
              @click="$router.push({ name: 'login' })"
              v-track="'logged-out__page-login-again-btn'"
            >
              Login Again
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { defer } from "@/utils";
import { mapGetters, mapActions } from "vuex";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";
import {
  IDENTITY_AUTH_ERROR_CODES,
  MAROPOST_ADMIN_PANEL,
} from "@/constants/app";

/**
 * Logout view
 */
export default {
  name: "LogOut",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      timerRef: null,
      adminIframe: null,
    };
  },
  /**
  |--------------------------------------------------
  | Componenets
  |--------------------------------------------------
  */
  components: {
    AuthFormsWrapper,
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      logoutUser: `auth/logoutUser`,
    }),

    /**
     * Logouts the user from maropost products
     * @description Expires the cookies set for maropost products authetication
     */
    async logout() {
      try {
        this.isLoading = true;

        if (this.isLoggedIn) await this.logoutUser();
        this.toLogin();
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Redirects user to login page after 10 seconds
     */
    toLogin() {
      const callback = () => this.$router.push({ name: "login" });
      this.timerRef = defer(callback, 1000_0);
    },
    logoutAdminPanel() {
      const iframe = document.createElement("iframe");
      iframe.src = `${MAROPOST_ADMIN_PANEL}/#/logged-out`;
      iframe.width = 0;
      iframe.height = 0;
      iframe.id = "admin-panel-logout-page-iframe";
      iframe.className = "d-none";
      document.body.appendChild(iframe);
      this.adminIframe = iframe;
    },
    async iframeMessageHandler(event) {
      if (event.data?.code === IDENTITY_AUTH_ERROR_CODES.loggedOut) {
        console.log("User logged out in Admin panel");
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.logoutAdminPanel();
    window.addEventListener("message", this.iframeMessageHandler, false);
    this.logout();
  },
  /**
   |--------------------------------------------------
   | Destroyed lifecycle hook
   |--------------------------------------------------
   */
  destroyed() {
    if (this.timerRef) clearTimeout(this.timerRef);
    window.removeEventListener("message", this.iframeMessageHandler, false);
    if (this.adminIframe) this.adminIframe.remove();
  },
};
</script>
